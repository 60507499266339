@font-face {
  font-family: 'Formular';
  src: url('../assets/fonts/Formular/Formular-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Formular';
  src: url('../assets/fonts/Formular/Formular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Formular';
  src: url('../assets/fonts/Formular/Formular-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Formular';
  src: url('../assets/fonts/Formular/Formular-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
