@import '../../../styles/variables';

.missionWrapper {
  flex: 1 1 auto;
  width: 90%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  position: relative;
}

.decor {
  position: absolute;
  top: 0%;
  right: 30%;
  z-index: -1;
}

.decor2 {
  position: absolute;
  bottom: -10%;
  left: -15%;
  z-index: -1;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 100px;
  font-weight: 300;
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
}

.text {
}

.boldText {
  font-weight: 500;
}

.image {
  object-fit: cover;
  height: 500px;
}

.icons {
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    height: 40px;
  }
}

@media (max-width: 1850px) {
  .title {
    font-size: 80px;
  }
  .textBlock {
    font-size: 18px;
  }
  .image {
    height: 450px;
  }
}

@media (max-width: 1520px) {
  .title {
    font-size: 60px;
  }
  .textBlock {
    font-size: 16px;
  }
  .image {
    height: 400px;
  }
  .icons {
    svg {
      height: 30px;
      width: max-content;
    }
  }
}

@media (max-width: 1180px) {
  .title {
    font-size: 50px;
  }
}

@media (max-width: 1100px) {
  .missionWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    margin: 0 auto 50px;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
  .decor {
    top: 0%;
    right: -20%;
  }

  .decor2 {
    top: unset;
    bottom: -20%;
    left: -30%;
  }

  .left,
  .right {
    width: 100%;
    gap: 25px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 40px;
  }
  .icons {
    svg {
      height: 20px;
      width: max-content;
    }
  }
  .decor2 {
    bottom: -30%;
    left: -30%;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 30px;
  }
  .image {
    height: 300px;
  }
  .icons {
    svg {
      height: 18px;
      width: max-content;
    }
  }
}
