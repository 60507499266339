@import 'variables';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button {
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Formular', sans-serif;
}

input {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: 'Formular', sans-serif;
  color: inherit;

  &::placeholder {
    color: inherit;
    font-family: 'Formular', sans-serif;
  }
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

address {
  font-style: normal;
}

body {
  color: $black;
  @extend .fontBase;
}

html {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background: $grey;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $grey;
  }
}
