@import '../../styles/variables';

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content {
  color: $white;
  background-color: $black;
  padding: 20px;
  position: relative;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.modalTitle {
  text-align: center;
  font-weight: 500;
}

.modalText {
  text-align: center;
}

.modalButton {
  margin: 0 auto;
  width: 80%;
}
