@import '../../styles/variables';

.page {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1 0 auto;
}

.decor {
  position: absolute;
  bottom: -20%;
  right: -20%;
  z-index: -1;
}

.wrapperInner {
  display: flex;
  gap: 50px;
}

.right {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image {
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
}

.name {
  font-weight: 500;
  font-size: 26px;
}

.brand {
  font-size: 20px;
}

.text {
  a {
    text-decoration: underline;
  }
}

.social {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.button {
  width: max-content;
}

@media (max-width: 1600px) {
  .decor {
    bottom: -20%;
    right: -20%;
  }
}

@media (max-width: 1330px) {
  .decor {
    bottom: -0%;
    right: -25%;
  }
}

@media (max-width: 1100px) {
  .wrapperInner {
    flex-direction: column-reverse;
  }
  .right {
    width: 100%;
    flex-direction: row;
  }
  .image {
    width: 300px;
  }
  .about {
    justify-content: space-between;
  }
  .decor {
    bottom: unset;
    top: -5%;
    right: -20%;
  }
}

@media (max-width: 900px) {
  .decor {
    top: -5%;
    right: -30%;
  }
}
@media (max-width: 768px) {
  .wrapper {
    margin-top: 0;
  }
  .image {
    width: 200px;
  }
  .name {
    font-size: 20px;
  }
  .brand {
    font-size: 16px;
  }
  .decor {
    top: 5%;
    right: -50%;
  }
}

@media (max-width: 500px) {
  .right {
    flex-direction: column;
  }
  .image {
    width: 100%;
  }
  .name {
    font-size: 26px;
  }
  .brand {
    font-size: 20px;
  }
  .button {
    width: 200px;
  }
  .decor {
    display: none;
  }
}
