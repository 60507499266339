@import '../../styles/variables';

.footer {
  flex: 0 0 auto;
  background-color: $grey;
  position: relative;
}

.content {
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 200px;
}

.address {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.social,
.socialUp {
  display: flex;
  gap: 20px;
}

.icon {
  height: 40px;
}

.socialUp {
  cursor: pointer;
}

@media (max-width: 1400px) {
  .footer {
    font-size: 14px;
  }
  .logo {
    width: 150px;
  }
  .address {
    gap: 25px;
  }
  .social,
  .socialUp {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

@media (max-width: 1100px) {
  .footer {
    font-size: 20px;
  }
  .logo {
    width: 200px;
  }
  .address {
    flex-direction: column;
    gap: 30;
  }
  .social,
  .socialUp {
    margin-top: auto;
    svg {
      height: 40px;
      width: 40px;
    }
  }
  .tel {
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .footer {
    font-size: 16px;
  }
  .logo {
    width: 130px;
  }
  .address {
    flex-direction: column;
    gap: 15px;
  }
  .social,
  .socialUp {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

@media (max-width: 580px) {
  .content {
    position: relative;
    flex-direction: column;
    gap: 20px;
    align-items: unset;
  }
  .socialUp {
    bottom: 40px;
    right: 0;
    position: absolute;
  }
}
