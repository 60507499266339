@import '../../styles/variables';

$time: 10s;

.text {
  font-size: 1vw;
  font-weight: 500;
}

.runningLineWrapper {
  overflow: hidden;
}

.runningLine {
  position: relative;
  height: 2vw;
  margin: 20px 0;
  overflow: hidden;
}

.firstWrapper,
.secondWrapper {
  display: flex;
  align-items: center;
  gap: 1.1vw;
  width: 100%;
  transform: translate(100%, 0);
  position: absolute;
  top: 0;
  right: 0;
  animation: line $time infinite linear forwards;
}

.decor {
  width: 1vw;
  height: 1vw;
  transform: rotate(74deg);
}

.firstWrapper {
  // transform: translate(50%, 0);
}

.secondWrapper {
  right: 1%;
  // transform: translate(100%, 0);
  // animation: $time line2 $time/2 infinite linear forwards;
  animation: line2 $time infinite linear forwards;
}

@keyframes line {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(50%, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes line2 {
  0% {
    transform: translate(-100%, 0);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@media (max-width: 1100px) {
  .text {
    font-size: 2vw;
  }
  .decor {
    width: 2vw;
    height: 2vw;
  }
  .runningLine {
    height: 4vw;
  }
  .firstWrapper,
  .secondWrapper {
    gap: 1.1vw;
  }
}

@media (max-width: 600px) {
  .text {
    font-size: 5vw;
  }
  .decor {
    width: 5vw;
    height: 5vw;
  }
  .runningLine {
    height: 8vw;
  }
  .firstWrapper,
  .secondWrapper {
    gap: 1.1vw;
  }
}
