@import '../../styles/variables';

.burgerMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $halfBlack;
  backdrop-filter: blur(12px);
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  color: $white;
  
}

.content {
  display: flex;
  flex-direction: column;
}

.nav {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 50px;

  svg {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.social {
  display: flex;
  gap: 20px;
}

@media (max-width: 500px) {
  .nav {
    font-size: 24px;
  }
}
