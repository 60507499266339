@import '../../styles/variables';

.page {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

@media (max-width: 1100px) {
  .title {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}
