@import '../../styles/variables';

.banner {
  display: none;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.images {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  transition: all 0.3s linear;
}

.image {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .image {
    &:hover {
      width: 300px;
    }
  }
}

@media (max-width: 768px) {
  .image {
    &:hover {
      width: 200px;
    }
  }
}
