@import '../../styles/variables';

.input {
  font-size: 20px;
  padding: 10px 30px;
  width: 300px;
  border: 2px solid $red;
  text-align: center;
}

@media (max-width: 1400px) {
  .input {
    font-size: 16px;
    width: 250px;
  }
}

@media (max-width: 768px) {
  .input {
    font-size: 14px;
    width: 200px;
  }
}

@media (max-width: 550px) {
  .input {
    width: 130px;
  }
}
