@import '../../styles/variables';

.slider {
  position: relative;
  width: 70%;
  height: 700px;
}

.galleryImage {
  background-color: $white;
  height: 700px;
  object-fit: contain;
}
.dots {
  position: relative;
  top: -20px;
  .dot {
    button {
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $grey;
      }
    }
  }
  .dotActive {
    button {
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $red;
        opacity: 1;
      }
    }
  }
}

.prevArrow {
  position: absolute;
  top: 50%;
  left: 1%;
  z-index: 1;
  mix-blend-mode: difference;

  svg {
    transform: rotate(180deg);
    width: 50px;
    height: 50px;
  }
}

.nextArrow {
  position: absolute;
  top: 50%;
  right: 1%;
  mix-blend-mode: difference;

  z-index: 1;

  svg {
    width: 50px;
    height: 50px;
  }
}

.stretch {
  object-fit: cover;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: auto;
  }

  .close {
    cursor: pointer;
    color: $white;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

@media (max-width: 1700px) {
  .slider {
    height: 600px;
  }
  .galleryImage {
    height: 600px;
  }
}

@media (max-width: 1400px) {
  .slider {
    height: 500px;
  }
  .galleryImage {
    height: 500px;
  }
}

@media (max-width: 1100px) {
  .slider {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .slider {
    height: 300px;
  }
  .galleryImage {
    height: 300px;
  }
}
