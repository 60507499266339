@import '../../styles/variables';

.page {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.wrapper {
  min-height: 426px;
  background-color: $black;
}

.title {
  color: $white;
  width: 80%;
  margin: 0 auto;
  font-size: 100px;
  font-weight: 300;
}

.sliderWrapper {
  position: relative;
  top: 50px;
  margin: 0 auto;
  width: 95%;
}

.prevArrow {
  position: absolute;
  top: 50%;
  left: -2%;
  color: $white;
  z-index: 1;
  svg {
    transform: rotate(180deg);
    width: 50px;
    height: 50px;
  }
}

.nextArrow {
  position: absolute;
  top: 50%;
  right: -2%;
  color: $white;
  z-index: 1;

  svg {
    width: 50px;
    height: 50px;
  }
}

.event {
  cursor: pointer;
  position: relative;
  height: 300px;
  padding: 0 20px;
}

.eventImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.eventText {
  width: 80%;
  color: $white;
  position: absolute;
  top: 70%;
  left: 10%;
}
.eventTitle {
  font-weight: 500;
}

.calendarWrapper {
  flex: 1 1 auto;
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 50px;
  background-color: $black;
  color: $white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 100px;
}

.pickFormat {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.buttons {
  display: flex;
  gap: 20px;
}

.buttonPick {
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  width: 250px;
  border-radius: 50px;
  border: 1px solid $white;
  transition: 0.3s;
}

.buttonPickActive {
  color: $red;
  border: 1px solid $red;
}

@media (max-width: 1300px) {
  .title {
    font-size: 70px;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    min-height: 390px;
  }
  .calendarWrapper {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .pickFormat {
    align-self: unset;
  }
  .calendar {
    width: 70%;
  }

  .sliderWrapper {
  }
  .slider {
    position: relative;
    margin: 0 auto;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    min-height: 350px;
  }
  .title {
    font-size: 50px;
  }
  .calendar {
    width: 90%;
  }
  .calendarWrapper {
    gap: 50px;
  }
  .event {
    height: 200px;
  }
  .eventText {
    width: 80%;
    top: 50%;
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .buttonPick {
    width: 200px;
  }
}
@media (max-width: 500px) {
  .title {
    font-size: 30px;
  }
  .buttons {
    flex-direction: column;
  }
  .buttonPick {
    width: 300px;
  }
  .eventTitle {
  }
}
