@import '../../styles/variables';

.button {
  font-size: 20px;
  padding: 10px 30px;
  width: 300px;
  background-color: $red;
  color: $white;
  border: 2px solid $red;
}

@media (max-width: 1400px) {
  .button {
    font-size: 16px;
    width: 250px;
  }
}

@media (max-width: 768px) {
  .button {
    font-size: 14px;
    width: 200px;
  }
}

@media (max-width: 550px) {
  .button {
    padding: 10px;
    width: 130px;
  }
}
