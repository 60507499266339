@import '../../styles/variables';

.page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  flex: 1 1 auto;
  position: relative;
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.decor {
  position: absolute;
  z-index: -1;
  bottom: -20%;
  right: -20%;
}

.wrapperInner {
  display: flex;
  gap: 50px;
}

.right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 20px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.name {
  font-weight: 500;
  font-size: 36px;
}

.brand {
  font-size: 26px;
}

.social {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.button {
  width: max-content;
}

.expertWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.expert {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  grid-gap: 100px;
  max-width: 1200px;
  img {
    width: 250px;
    align-items: center;
    justify-content: center;
  }
  text-align: center;
  p {
    margin-top: 10px;
    width: 250px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    text-decoration: underline;
  }
}

@media (max-width: 1100px) {
  .expert {
    grid-template-columns: repeat(1, 1fr);
  }
  .wrapperInner {
    flex-direction: column-reverse;
  }
  .right {
    width: 100%;
    flex-direction: row;
    justify-content: end;
  }
  .image {
    width: 300px;
  }
  .about {
    justify-content: space-between;
  }
  .button {
    width: 250px;
  }
  .decor {
    bottom: -15%;
    right: -30%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-top: 0;
  }
  .image {
    width: 200px;
  }
  .name {
    font-size: 30px;
  }
  .brand {
    font-size: 20px;
  }
  .decor {
    bottom: -15%;
    right: -40%;
  }
}

@media (max-width: 500px) {
  .image {
    width: 100%;
  }
  .name {
    font-size: 26px;
  }
  .brand {
    font-size: 20px;
  }
  .button {
    width: 200px;
  }
  .decor {
    bottom: -25%;
    right: -40%;
  }
}

h2 {
  margin-top: 50px;
  padding: 35px;
  background-color: #E61B1F;
  color: white;
  text-align: center;
  border-radius: 10px;
  transition: background-color 0.5s ease;
  text-align: center;
}

h3 {
  background-color: black;
  color: white;
  padding: 10px;
  width: fit-content;
  transition: background-color 0.5s ease;
}

h3:hover {
  background-color: #E61B1F;
}