@import '../../styles/variables';

.header {
  font-size: 18px;
  height: 70px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav {
  width: 56%;
}

.navList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 20px;

  li {
    width: 100%;
  }
}

.logo {
  cursor: pointer;
  width: 30%;
  min-width: 300px;
}

.navTablet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.navLink {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid $white;

  &:hover {
    border-bottom: 1px solid $black;
  }
}

.navLinkActive {
  border-bottom: 1px solid $black;
}

.social {
  display: flex;
  gap: 20px;
}

@media (max-width: 1210px) {
  .navLink {
    padding: 20px 10px;
  }
}
@media (max-width: 1100px) {
  .header {
    font-size: 16px;
    flex-wrap: wrap;
    height: 140px;
  }
  .logo {
    width: 70%;
  }
  .social {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .header {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .logo {
    width: 250px;
    min-width: unset;
  }
  .social {
    width: unset;
  }
}
