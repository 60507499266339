@import '../../styles/variables';

.page {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1 1 auto;
}

.decor {
  position: absolute;
  bottom: -15%;
  right: -10%;
  z-index: -1;
}

.title {
  font-size: 50px;
}

.date {
  font-size: 26px;
}

.text {
  margin-top: 20px;

  a {
    text-decoration: underline;
  }
}

.none {
  display: none;
}

.slider {
  z-index: 0;
  width: 60%;
  height: 500px;
}

.sliderImage {
  height: 500px;
}

.floatLeft {
}

.floatRight {
  float: right;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
}

.navigateButtons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.button {
  width: auto;
  padding: 10px 20px;
}

.newsWrapper {
  background-color: $black;
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  height: 530px;
  transition: 0.3s;
}

.close {
  position: absolute;
  right: 5px;
  top: 50%;
}

.closeIcon {
  transition: 0.3s;
  transform: rotate(180deg);
}
.closeIconActive {
  transform: rotate(0deg);
}

.news {
  transition: 0.5s;
  color: $white;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $darkGrey;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey;
  }
}

.newsWrapperActive {
  padding: 0;
  width: 30px;
}

.new {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.newTitle {
  font-size: 20px;
  font-weight: 500;
}

.newDesc {
  margin: 20px 0 40px;
}

.buttons {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subWrapper {
  display: flex;
  justify-content: space-between;
}

.sendText {
  text-align: end;
}

@media (max-width: 1700px) {
  .floatRight {
    width: 35%;
  }
  .buttons {
    width: 50%;
  }
  .subWrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1500px) {
  .slider {
    width: 50%;
  }

  .floatRight {
    width: 40%;
  }
}

@media (max-width: 1400px) {
  .subText,
  .sendText {
    font-size: 16px;
  }
}

@media (max-width: 1300px) {
  .navigateButtons {
    justify-content: end;
  }
  .slider {
    width: 100%;
  }
  .floatRight {
    top: 10%;
    right: -13%;
    z-index: 1;
    position: absolute;
    width: max-content;
  }
  .newsWrapper {
    height: 450px;
    width: 300px;
  }
  .newsWrapperActive {
    width: 30px;
  }
  .news {
    height: 450px;
  }
  .buttons {
    width: 60%;
  }
  .title {
    margin-top: 30px;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    gap: 20px;
  }

  .newsWrapper {
    padding: 20px 15px;
  }
  .newDate,
  .newDesc {
    font-size: 14px;
  }
  .newTitle {
    font-size: 18px;
  }
  .newDesc {
    margin: 10px 0 20px;
  }
  .title {
    font-size: 40px;
  }
  .date {
    font-size: 20px;
  }
  .buttons {
    width: 70%;
  }
}

@media (max-width: 950px) {
  .buttons {
    width: 90%;
  }
  .decor {
    bottom: 15%;
    right: -40%;
  }
}

@media (max-width: 900px) {
  .newsWrapper {
    height: 400px;
    width: 300px;
  }
  .newsWrapperActive {
    width: 30px;
  }
  .news {
    height: 400px;
  }
  .buttons {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .subText,
  .sendText {
    font-size: 14px;
  }

  .floatRight {
  }
  .newDate,
  .newDesc {
    font-size: 12px;
  }
  .newTitle {
    font-size: 16px;
  }
  .title {
    margin-top: 0;
    font-size: 30px;
  }
  .date {
    font-size: 18px;
  }
}

@media (max-width: 685px) {
  .decor {
    bottom: 15%;
    right: -60%;
  }
}

@media (max-width: 500px) {
  .floatRight {
  }
  .title {
    font-size: 26px;
  }
  .date {
    font-size: 16px;
  }
  .decor {
    bottom: 30%;
    right: -80%;
  }
}
