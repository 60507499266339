@import '../../styles/variables';

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  width: 80%;
  margin: 50px auto;
}

.title {
  display: flex;
  align-items: center;

  h1 {
    font-size: 100px;
    font-weight: 300;
    margin-right: 20px;
  }

  svg {
    height: 80px;
    width: 80px;
    transform: rotate(74deg);
  }
}

.news {
}

.new {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.newDate {
  text-align: end;
}

.newImage {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.newTitle {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.25em;
  min-height: 3.75em;
}

.newDesc {
  margin-top: 20px;
  text-align: center;
}

.buttons {
  width: 80%;
  margin: 50px auto;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subWrapper {
  display: flex;
  gap: 20px;
}

.sendText {
  text-align: end;
}

.pages {
  text-align: center;
  font-weight: 300;
  font-size: 24px;

  .span {
    cursor: pointer;
    margin-left: 5px;
  }

  .spanDots {
    cursor: unset;
  }

  .activeSpan {
    font-weight: 500;
  }
}

.eventsButton {
  margin-left: auto;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1400px) {
  .subText,
  .sendText {
    font-size: 16px;
  }
}

@media (max-width: 1300px) {
  .title {
    h1 {
      font-size: 70px;
      margin-right: 20px;
    }

    svg {
      height: 50px;
      width: 50px;
      transform: rotate(74deg);
    }
  }
  .news {
    grid-template-columns: repeat(2, 1fr);
  }
  .newTitle {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .title {
    flex-wrap: wrap;
    h1 {
      font-size: 50px;
      margin-right: 10px;
    }

    svg {
      height: 30px;
      width: 30px;
      transform: rotate(74deg);
    }
  }
  .newImage {
    height: 200px;
  }
  .newTitle {
    min-height: unset;
  }
  .subText,
  .sendText {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .news {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 500px) {
  .title {
    h1 {
      font-size: 30px;
      margin-right: 10px;
    }

    svg {
      height: 25px;
      width: 25px;
      transform: rotate(74deg);
    }
  }
}
