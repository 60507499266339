@import '../../../styles/variables';

.newsWrapper {
  flex: 1 1 auto;
  width: 80%;
  margin: 0 auto 50px;
}

.newsWrapper1100 {
  width: 100%;
}

.leftWrapper {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.prevNew,
.nextNew {
  padding-left: 100px;
}

.textWrapper {
  cursor: pointer;
  width: max-content;
}

.desc,
.descNowNew {
  font-weight: 500;
}

.nowNewWrapper {
  display: flex;
  justify-content: space-between;
}

.nowNew {
  width: 60%;
  background-color: $black;
  padding: 40px;
  padding-left: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 200px;
}

.leftBlock {
}

.dateNowNew,
.descNowNew {
  color: $white;
}

.button {
  margin-top: 100px;
  color: $white;
  font-size: 16px;
}

.rightWrapper {
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.subText {
  margin-right: 75px;
}

.sendText {
  text-align: end;
}

.buttonsWrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
}

.subText2 {
  text-align: end;
}

.buttons {
  display: flex;
  gap: 20px;
}

@media (max-width: 1400px) {
  .prevNew,
  .nextNew,
  .nowNew {
    padding-left: 50px;
  }
  .leftWrapper {
    font-size: 16px;
  }
  .button {
    font-size: 14px;
  }
  .subText {
    margin-right: 40px;
  }
}

@media (max-width: 1100px) {
  .prevNew,
  .nextNew {
    padding-left: 0px;
  }

  .leftBlock {
    padding-left: 10%;
  }

  .image {
    width: 150px;
  }

  .prevNew {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .nextNew {
    width: 80%;
    margin: 0 auto;
  }
  .leftWrapper {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .nowNew {
    width: 90%;
    padding-left: 2%;
  }
  .leftWrapper {
    font-size: 14px;
  }
  .button {
    font-size: 12px;
  }
  .image {
    width: 120px;
  }
  .buttons {
    gap: 10px;
  }
}
