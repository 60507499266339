@import '../../styles/variables';

.page {
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1 1 auto;
  position: relative;
  width: 80%;
  margin: 50px auto;
}

.title {
  display: flex;
  align-items: center;
  h1 {
    font-size: 100px;
    font-weight: 300;
    margin-right: 20px;
  }

  svg {
    height: 80px;
    width: 80px;
    transform: rotate(74deg);
  }
}

.categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.category {
  aspect-ratio: 1;
  background-image: url('../../assets/images/brands/brands1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.category2 {
  aspect-ratio: 1;
  background-image: url('../../assets/images/brands/brands2.webp');
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.categoryActive {
  filter: grayscale(100%);
}

.designers {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  img {
    cursor: pointer;
    object-fit: cover;
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    transition: 0.5s;
  }
}

.designer {
  position: relative;

  &:hover {
    img {
      filter: grayscale(0%);
    }

    .designerText {
      bottom: 0;
    }
  }
}

.designerText {
  pointer-events: none;
  color: $white;
  text-align: center;
  left: 0;
  right: 0;
  bottom: -20%;
  position: absolute;
  transition: 0.5s;
}

.name {
  font-size: 26px;
  font-weight: 500;
}

.brand {
  font-size: 16px;
}

.decor {
  position: absolute;
  bottom: -3%;
  right: -20%;
  z-index: -1;
}

@media (max-width: 1600px) {
  .category,
  .category2 {
    font-size: 60px;
  }
}

@media (max-width: 1300px) {
  .category,
  .category2 {
    font-size: 40px;
  }
  .categories {
    gap: 25px;
  }
  .title {
    h1 {
      font-size: 70px;
      margin-right: 20px;
    }

    svg {
      height: 50px;
      width: 50px;
      transform: rotate(74deg);
    }
  }
  .designers {
    margin-top: 50px;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
  .decor {
    bottom: 0%;
    right: -20%;
  }
}

@media (max-width: 900px) {
  .decor {
    bottom: -10%;
    right: -20%;
  }
}

@media (max-width: 768px) {
  .title {
    h1 {
      font-size: 50px;
      margin-right: 10px;
    }

    svg {
      height: 30px;
      width: 30px;
      transform: rotate(74deg);
    }
  }
  .categories {
    margin-top: 25px;
    grid-template-columns: 1fr;
  }
  .category,
  .category2 {
    aspect-ratio: 2/1;
  }
  .designerText {
    bottom: -30%;
  }
  .name {
    font-size: 20px;
  }

  .brand {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .designers {
    grid-template-columns: repeat(1, 1fr);
  }
  .decor {
    display: none;
  }
}

@media (max-width: 500px) {
  .title {
    h1 {
      font-size: 30px;
      margin-right: 10px;
    }

    svg {
      height: 25px;
      width: 25px;
      transform: rotate(74deg);
    }
  }
}
